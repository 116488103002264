import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import GatsbyHead from '../components/GatsbyHead'
import LayoutWrapper from '../components/LayoutWrapper'
import Link from '../components/Link'
import PageBanner from '../components/PageBanner'
import PageSection from '../components/PageSection'
import Section from '../components/Section'
import Wrapper from '../components/Wrapper'
import { useIntl } from '../utils/IntlContext'
import { getLocalePathPrefix, getLocalizedRootUrlPrefix, urlPathJoin } from '../utils/locales'

export const query = graphql`
  query International($contentful_id: String!, $locale: String!) {
    contentfulReactPage(contentful_id: { eq: $contentful_id }, node_locale: { eq: $locale }) {
      id
      contentful_id
      slug
      node_locale
      languages
      meta_title
      meta_description
      banner {
        ...PageBanner
      }
      sections {
        ...PageSection
      }
    }
    countries: allContentfulCountry(
      filter: { node_locale: { eq: $locale }, country: { nin: [null, ""] }, slug: { nin: [null, ""] } }
      sort: { fields: country, order: ASC }
    ) {
      edges {
        node {
          id
          heading
          country
          slug
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 100, layout: FIXED)
              }
            }
          }
        }
      }
    }
    successStoriesPage: contentfulReactPage(
      contentful_id: { eq: "35SjrERFSPauSTpENszW1i" }
      node_locale: { eq: $locale }
    ) {
      slug
    }
  }
`

const CountryLink = styled(Link)`
  border-radius: var(--border-radius);
  margin-bottom: 20px;
  padding: var(--spacing-lg);
  text-align: center;
  transition: box-shadow 250ms;

  &:hover {
    box-shadow: var(--box-shadow-primary-large);
    transition: box-shadow 250ms;
  }

  img {
    border: 1px solid var(--color-background-light);
  }

  span {
    display: block;
    line-height: 28px;
  }
`

const Country = ({ country, image, to }) => (
  <CountryLink to={to}>
    <GatsbyImage
      image={image}
      alt=""
    />
    <span>{country}</span>
  </CountryLink>
)

const CountryContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0 20px;
`

const International = ({
  pageContext: { translatedPages },
  data: {
    contentfulReactPage: { contentful_id, slug, banner, sections },
    countries,
    successStoriesPage,
  },
}) => {
  const { locale } = useIntl()
  const pageBasePath = urlPathJoin(getLocalePathPrefix(locale), slug)

  const countryNodes = countries.edges.map((edge) => edge.node)

  return (
    <LayoutWrapper translatedPages={translatedPages}>
      <PageBanner banner={banner} />
      <Section
        className="section"
        align="center"
        bgColor="Light Blue"
        hasComponent>
        <Wrapper>
          <CountryContainer>
            {countryNodes.map((country) => (
              <Country
                key={country.slug}
                image={getImage(country.image.localFile)}
                to={urlPathJoin(pageBasePath, country.slug)}
                country={country.country}
              />
            ))}
          </CountryContainer>
        </Wrapper>
      </Section>
      <PageSection
        contentfulId={contentful_id}
        successStoriesPath={getLocalizedRootUrlPrefix(successStoriesPage, locale)}
        sections={sections}
      />
    </LayoutWrapper>
  )
}

/** @type {import('gatsby').HeadFC} */
export const Head = ({
  pageContext: { canonicalUrl, translatedPages },
  data: {
    contentfulReactPage: { meta_title, meta_description },
  },
}) => {
  return (
    <GatsbyHead
      pageTitle={meta_title}
      metaDescription={meta_description}
      canonicalUrl={canonicalUrl}
      translatedPages={translatedPages}
    />
  )
}

export default International
